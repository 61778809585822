import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import {
  fetchReadCloudParkTicketService,
  fetchBuyPrivateTicketService,
  OpenPrivateTicketType,
} from '../../services/PrivateTicketPurchase';
import {
  PrivateTicketDataType,
  PrivateTicketPurchaseStateType,
} from '../types';

const initialState: PrivateTicketPurchaseStateType = {
  privateTicketData: null,
  privateOpenTicketData: null,
  buyPrivateTicketIsOpen: false,
  cloudParkTicketLoading: false,
  privateTicketPurchaseLoading: false,
  editCPFIsOpen: false,
  editCPFLoading: false,
};

export const fetchReadCloudParkTicket = createAsyncThunk(
  'readCloudParkTicket',
  async (params: { driverId: string; cloudParkTicketId: string }) => {
    return await fetchReadCloudParkTicketService(params);
  },
);

export const fetchBuyPrivateTicket = createAsyncThunk(
  'buyPrivateTicket',
  async (params: {
    driverId: number;
    privateParkingId: string;
    paymentMethodId: number;
    cloudParkTicketId: string;
    cloudParkToken: string;
    cloudParkId: string | null;
    cloudParkContract: string;
    cloudParkEntryDate: string;
    cloudParkPlate: string;
    cloudParkPrice: number;
  }) => {
    return await fetchBuyPrivateTicketService(params);
  },
);

export const privateTicketPurchaseSlice = createSlice({
  name: 'privateTicketPurchase',
  initialState,
  reducers: {
    togglePrivateTicketPurchaseLoading(state) {
      state.privateTicketPurchaseLoading = !state.privateTicketPurchaseLoading;
    },
    toggleBuyPrivateTicketIsOpen(state) {
      state.buyPrivateTicketIsOpen = !state.buyPrivateTicketIsOpen;
    },
    setPrivateTicketData(
      state,
      action: PayloadAction<PrivateTicketDataType | null>,
    ) {
      return {
        ...state,
        privateTicketData: action.payload,
      };
    },
    resetPrivateTicketData(state) {
      return {
        ...state,
        privateTicketData: null,
      };
    },
    setOpenPrivateTicketData(
      state,
      action: PayloadAction<OpenPrivateTicketType | null>,
    ) {
      return {
        ...state,
        openPrivateTicketData: action.payload,
      };
    },
    toggleEditCPFPrivateTicketIsOpen(state) {
      return {
        ...state,
        editCPFIsOpen: !state.editCPFIsOpen,
      };
    },
    toggleEditCPFPrivateTicketLoading(state) {
      return {
        ...state,
        editCPFLoading: !state.editCPFLoading,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReadCloudParkTicket.pending, (state) => {
      return {
        ...state,
        cloudParkTicketLoading: true,
      };
    }),
      builder.addCase(fetchReadCloudParkTicket.fulfilled, (state, res) => {
        const now = moment();
        const diff = moment.duration(
          now.diff(moment(res.payload.data.cloudParkEntryDate)),
        );
        return {
          ...state,
          privateTicketData: {
            driverId: res.payload.driverId,
            privateParkingId: res.payload.data.privateParkingId,
            paymentMethodId: null,
            cloudParkTicketId: res.payload.data.cloudParkTicketId,
            cloudParkToken: res.payload.data.cloudParkToken,
            cloudParkId: res.payload.data.cloudParkId,
            cloudParkContract: res.payload.data.cloudParkContract,
            cloudParkEntryDate: moment(
              res.payload.data.cloudParkEntryDate,
            ).format('DD/MM/YYYY HH:mm:ss'),
            cloudParkPlate: res.payload.data.cloudParkPlate,
            cloudParkPrice: res.payload.data.cloudParkPrice.toString(),
          },
          cloudParkTicketLoading: false,
        };
      }),
      builder.addCase(fetchReadCloudParkTicket.rejected, (state, res) => {
        return {
          ...state,
          cloudParkTicketLoading: false,
        };
      });

    builder.addCase(fetchBuyPrivateTicket.pending, (state) => {
      return {
        ...state,
        privateTicketPurchaseLoading: true,
      };
    }),
      builder.addCase(fetchBuyPrivateTicket.fulfilled, (state, res) => {
        return {
          ...state,
          // openTicket: res.payload.data,
          privateTicketPurchaseLoading: false,
        };
      }),
      builder.addCase(fetchBuyPrivateTicket.rejected, (state, res) => {
        return {
          ...state,
          privateTicketPurchaseLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  togglePrivateTicketPurchaseLoading,
  toggleBuyPrivateTicketIsOpen,
  setPrivateTicketData,
  setOpenPrivateTicketData,
  toggleEditCPFPrivateTicketIsOpen,
  toggleEditCPFPrivateTicketLoading,
} = privateTicketPurchaseSlice.actions;
