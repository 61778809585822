import axios from 'axios';
import { setupInterceptors } from './interceptors';

/**
 * Here you can export functions with corresponding services / endpoints.
 * You can always use the services directly from the specific moduleServices
 * file as well.
 * This is to keep a track of all available services as a list.
 * I think it makes is easy to maintain when you have a long long list as your
 * app gradually scales.
 */

const axiosAPI = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
});

export const api = axiosAPI;
setupInterceptors();

export const TERMS_OF_USE =
  'https://zonaazul.jumppark.com.br/termo_uso_niteroi_rotativo.pdf';
export const CLOSE_ACCOUNT =
  'https://zonaazul.jumppark.com.br/exclusao-conta-niteroi-rotativo.pdf';

export * from './Auth';
export * from './History';
export * from './Irregularities';
export * from './Legal';
export * from './PaymentMethods';
export * from './Pix';
export * from './PrivateHistory';
export * from './PrivateTicketPurchase';
export * from './Profile';
export * from './TicketPurchase';
export * from './Vehicles';
