import { AnyAction, CombinedState, configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authSlice } from './slices/Auth';
import { historySlice } from './slices/History';
import { irregularitiesSlice } from './slices/Irregularities';
import { legalSlice } from './slices/Legal';
import { paymentMethodsSlice } from './slices/PaymentMethods';
import { pixSlice } from './slices/Pix';
import { privateHistorySlice } from './slices/PrivateHistory';
import { privateTicketPurchaseSlice } from './slices/PrivateTicketPurchase';
import { profileSlice } from './slices/Profile';
import { supportSlice } from './slices/Support';
import { ticketPurchaseSlice } from './slices/TicketPurchase';
import { vehiclesSlice } from './slices/Vehicles';

import {
  AuthStateType,
  HistoryStateType,
  LegalStateType,
  IrregularitiesStateType,
  PaymentMethodsStateType,
  PixStateType,
  PrivateHistoryStateType,
  PrivateTicketPurchaseStateType,
  ProfileStateType,
  SupportStateType,
  TicketPurchaseStateType,
  VehiclesStateType,
} from './types';

const combinedReducer = combineReducers({
  authReducer: authSlice.reducer,
  historyReducer: historySlice.reducer,
  legalReducer: legalSlice.reducer,
  irregularitiesReducer: irregularitiesSlice.reducer,
  paymentMethodsReducer: paymentMethodsSlice.reducer,
  pixReducer: pixSlice.reducer,
  privateHistoryReducer: privateHistorySlice.reducer,
  privateTicketPurchaseReducer: privateTicketPurchaseSlice.reducer,
  profileReducer: profileSlice.reducer,
  supportReducer: supportSlice.reducer,
  ticketPurchaseReducer: ticketPurchaseSlice.reducer,
  vehiclesReducer: vehiclesSlice.reducer,
});

const rootReducer = (
  state:
    | CombinedState<{
        authReducer: AuthStateType;
        historyReducer: HistoryStateType;
        legalReducer: LegalStateType;
        irregularitiesReducer: IrregularitiesStateType;
        paymentMethodsReducer: PaymentMethodsStateType;
        pixReducer: PixStateType;
        privateHistoryReducer: PrivateHistoryStateType;
        privateTicketPurchaseReducer: PrivateTicketPurchaseStateType;
        profileReducer: ProfileStateType;
        supportReducer: SupportStateType;
        ticketPurchaseReducer: TicketPurchaseStateType;
        vehiclesReducer: VehiclesStateType;
      }>
    | undefined,
  action: AnyAction,
) => {
  if (action.type === 'auth/logout') {
    localStorage.removeItem('NITROT:token');
    localStorage.removeItem('NITROT:driverId');
    window.dispatchEvent(new Event('storage'));
    state = undefined;
  }
  return combinedReducer(state, action);
};

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [
    // 'auth',
    'profile',
    'ticketPurchase',
    'privateTicketPurchase',
    'vehicles',
    'paymentMethods',
    'history',
    'privateHistory',
    'irregularities',
    'support',
    'pix',
    'legal',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Here is the export of the Store
export const Store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const Persistor = persistStore(Store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof Store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof Store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// This must be done this way so they can work with typescript
export const useDispatch = (): any => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;
