import { History, ShoppingCart } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ContentBox } from '../../components';
import { PRIVATE_HISTORY, PRIVATE_PURCHASE } from '../../routes';

export const Private: React.FC = () => {
  const navigate = useNavigate();

  return (
    <ContentBox>
      <Grid
        sx={{
          flexGrow: 1,
          margin: 'auto',
          display: 'flex',
          flexDirection: { sm: 'column', md: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          width: `100%`,
          maxWidth: '920px',
        }}
      >
        <Button
          variant='contained'
          label={
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: {
                  xs: '14px',
                  sm: '18px',
                  md: '20px',
                },
              }}
            >
              <ShoppingCart />
              &nbsp;&nbsp;&nbsp;Comprar um ticket privativo
            </Typography>
          }
          type='submit'
          onClick={() => {
            navigate(PRIVATE_PURCHASE);
          }}
          color='primary'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '130px',
            width: `100%`,
            maxWidth: '920px',
            margin: '20px',
            borderRadius: '10px',
          }}
        />
        <Button
          variant='contained'
          label={
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: {
                  xs: '14px',
                  sm: '18px',
                  md: '20px',
                },
              }}
            >
              <History />
              &nbsp;&nbsp;&nbsp;Histórico de tickets privativos
            </Typography>
          }
          type='submit'
          onClick={() => {
            navigate(PRIVATE_HISTORY);
          }}
          color='primary'
          fontSize='20px'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '130px',
            width: `100%`,
            maxWidth: '920px',
            margin: '20px',
            borderRadius: '10px',
          }}
        />
      </Grid>
    </ContentBox>
  );
};
