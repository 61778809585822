import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import {
  fetchAuthenticatePaymentService,
  fetchAuthenticatePrivatePaymentService,
  fetchRegularizeWithPixService,
  fetchBuyTicketWithPixService,
  fetchBuyPrivateTicketWithPixService,
  fetchCancelPixService,
  fetchCancelPrivatePixService,
  fetchOpenPixService,
  fetchRegularizeListWithPixService,
} from '../../services/Pix';
import { PixStateType } from '../types';

const initialState: PixStateType = {
  pixLoading: false,
  privatePixLoading: false,
  pixData: null,
  privatePixData: null,
  hasOpenPix: false,
  hasOpenPrivatePix: false,
  managePixIsOpen: false,
  managePrivatePixIsOpen: false,
  managePixIsLoading: false,
  managePrivatePixIsLoading: false,
  openPixServiceOrderId: null,
  openPixPrivatePaymentId: null,
};

export const fetchBuyTicketWithPix = createAsyncThunk(
  'buyTicketWithPix',
  async (params: {
    driverId: string;
    vehicleId: string;
    stayTime: string;
    price: string;
    paidPrice: string;
    routeId: string;
    paymentMethodId: string;
    driverDocument: string;
  }) => {
    return await fetchBuyTicketWithPixService(params);
  },
);

export const fetchBuyPrivateTicketWithPix = createAsyncThunk(
  'buyPrivateTicketWithPix',
  async (params: {
    driverId: number;
    privateParkingId: string;
    paymentMethodId: number;
    cloudParkTicketId: string;
    cloudParkToken: string;
    cloudParkId: string | null;
    cloudParkContract: string;
    cloudParkEntryDate: string;
    cloudParkPlate: string;
    cloudParkPrice: number;
    driverDocument: string;
  }) => {
    return await fetchBuyPrivateTicketWithPixService(params);
  },
);

export const fetchRegularizeWithPix = createAsyncThunk(
  'regularizeWithPix',
  async (params: {
    driverId: string;
    irregularityId: string;
    plate: string;
    type: string;
    entryDateTime: string;
    exitDateTime: string;
    stayTime: string;
    price: string;
    routeId: number;
    driverDocument: string;
  }) => {
    return await fetchRegularizeWithPixService(params);
  },
);

export const fetchRegularizeListWithPix = createAsyncThunk(
  'regularizeListWithPix',
  async (params: {
    driverId: string;
    plate: string;
    type: string;
    entryDateTime: string;
    exitDateTime: string;
    stayTime: string;
    price: number;
    routeId: number;
    vehicleId: number | null;
    irregularities: number[];
    observation: string;
    driverDocument: string;
  }) => {
    return await fetchRegularizeListWithPixService(params);
  },
);

export const fetchOpenPix = createAsyncThunk(
  'openPix',
  async (params: { driverId: string }) => {
    return await fetchOpenPixService(params);
  },
);

export const fetchAuthenticatePayment = createAsyncThunk(
  'authenticatePayment',
  async (params: {
    driverId: string;
    serviceOrderId: string;
    vehicleId: string;
  }) => {
    return await fetchAuthenticatePaymentService(params);
  },
);

export const fetchAuthenticatePrivatePayment = createAsyncThunk(
  'authenticatePrivatePayment',
  async (params: { driverId: string; privateParkingPaymentId: string }) => {
    return await fetchAuthenticatePrivatePaymentService(params);
  },
);

export const fetchCancelPix = createAsyncThunk(
  'cancelPix',
  async (params: { driverId: string; serviceOrderId: string }) => {
    return await fetchCancelPixService(params);
  },
);

export const fetchCancelPrivatePix = createAsyncThunk(
  'cancelPrivatePix',
  async (params: { driverId: string; privateParkingPaymentId: string }) => {
    return await fetchCancelPrivatePixService(params);
  },
);

export const pixSlice = createSlice({
  name: 'pix',
  initialState,
  reducers: {
    toggleManagePixIsOpen(state) {
      state.managePixIsOpen = !state.managePixIsOpen;
    },
    toggleManagePrivatePixIsOpen(state) {
      state.managePrivatePixIsOpen = !state.managePrivatePixIsOpen;
    },
    setHasOpenPix: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        hasOpenPix: action.payload,
      };
    },
    setHasOpenPrivatePix: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        hasOpenPrivatePix: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBuyTicketWithPix.pending, (state) => {
      return {
        ...state,
        pixLoading: true,
      };
    }),
      builder.addCase(fetchBuyTicketWithPix.fulfilled, (state, res) => {
        return {
          ...state,
          pixData: res.payload.data,
          pixLoading: false,
        };
      }),
      builder.addCase(fetchBuyTicketWithPix.rejected, (state, res) => {
        return {
          ...state,
          pixLoading: false,
        };
      });

    builder.addCase(fetchBuyPrivateTicketWithPix.pending, (state) => {
      return {
        ...state,
        privatePixLoading: true,
      };
    }),
      builder.addCase(fetchBuyPrivateTicketWithPix.fulfilled, (state, res) => {
        return {
          ...state,
          privatePixData: res.payload.data,
          privatePixLoading: false,
        };
      }),
      builder.addCase(fetchBuyPrivateTicketWithPix.rejected, (state, res) => {
        return {
          ...state,
          privatePixLoading: false,
        };
      });

    builder.addCase(fetchRegularizeWithPix.pending, (state) => {
      return {
        ...state,
        pixLoading: true,
      };
    }),
      builder.addCase(fetchRegularizeWithPix.fulfilled, (state, res) => {
        return {
          ...state,
          pixData: res.payload.data,
          hasOpenPix: true,
          pixLoading: false,
        };
      }),
      builder.addCase(fetchRegularizeWithPix.rejected, (state, res) => {
        return {
          ...state,
          pixLoading: false,
        };
      });

    builder.addCase(fetchRegularizeListWithPix.pending, (state) => {
      return {
        ...state,
        pixLoading: true,
      };
    }),
      builder.addCase(fetchRegularizeListWithPix.fulfilled, (state, res) => {
        return {
          ...state,
          pixData: res.payload.data,
          hasOpenPix: true,
          pixLoading: false,
        };
      }),
      builder.addCase(fetchRegularizeListWithPix.rejected, (state, res) => {
        return {
          ...state,
          pixLoading: false,
        };
      });

    builder.addCase(fetchOpenPix.pending, (state) => {
      return {
        ...state,
      };
    }),
      builder.addCase(fetchOpenPix.fulfilled, (state, res) => {
        return {
          ...state,
          hasOpenPix: res.payload.data.serviceOrderId ? true : false,
          openPixServiceOrderId: res.payload.data.serviceOrderId,
          hasOpenPrivatePix: res.payload.data.privateParking ? true : false,
          openPixPrivatePaymentId: res.payload.data.privateParking
            ? res.payload.data.privateParking.id
            : null,
        };
      }),
      builder.addCase(fetchOpenPix.rejected, (state, res) => {
        return {
          ...state,
          hasOpenPix: false,
          openPixServiceOrderId: null,
        };
      });

    builder.addCase(fetchAuthenticatePayment.pending, (state) => {
      return {
        ...state,
        pixLoading: true,
        managePixIsLoading: true,
      };
    }),
      builder.addCase(fetchAuthenticatePayment.fulfilled, (state, res) => {
        return {
          ...state,
          pixData: null,
          hasPendingTicket: false,
          hasOpenPix: false,
          pixLoading: false,
          managePixIsLoading: false,
        };
      }),
      builder.addCase(fetchAuthenticatePayment.rejected, (state, res) => {
        return {
          ...state,
          pixLoading: false,
          managePixIsLoading: false,
          // hasOpenPix: includes(JSON.stringify(res.payload), 'PIX_PND')
          //   ? true
          //   : false,
        };
      });

    builder.addCase(fetchAuthenticatePrivatePayment.pending, (state) => {
      return {
        ...state,
        privatePixLoading: true,
        managePrivatePixIsLoading: true,
      };
    }),
      builder.addCase(
        fetchAuthenticatePrivatePayment.fulfilled,
        (state, res) => {
          return {
            ...state,
            privatePixData: null,
            hasOpenPrivatePix: false,
            privatePixLoading: false,
            managePrivatePixIsLoading: false,
          };
        },
      ),
      builder.addCase(
        fetchAuthenticatePrivatePayment.rejected,
        (state, res) => {
          return {
            ...state,
            privatePixLoading: false,
            managePrivatePixIsLoading: false,
          };
        },
      );

    builder.addCase(fetchCancelPix.pending, (state) => {
      return {
        ...state,
        pixLoading: true,
        managePixIsLoading: true,
      };
    }),
      builder.addCase(fetchCancelPix.fulfilled, (state, res) => {
        return {
          ...state,
          pixData: null,
          hasOpenPix: false,
          pixLoading: false,
          managePixIsLoading: false,
        };
      }),
      builder.addCase(fetchCancelPix.rejected, (state, res) => {
        return {
          ...state,
          pixLoading: false,
          managePixIsLoading: false,
        };
      });

    builder.addCase(fetchCancelPrivatePix.pending, (state) => {
      return {
        ...state,
        privatePixLoading: true,
        managePrivatePixIsLoading: true,
      };
    }),
      builder.addCase(fetchCancelPrivatePix.fulfilled, (state, res) => {
        return {
          ...state,
          privatePixData: null,
          hasOpenPrivatePix: false,
          privatePixLoading: false,
          managePrivatePixIsLoading: false,
        };
      }),
      builder.addCase(fetchCancelPrivatePix.rejected, (state, res) => {
        return {
          ...state,
          privatePixLoading: false,
          managePrivatePixIsLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleManagePixIsOpen,
  toggleManagePrivatePixIsOpen,
  setHasOpenPix,
  setHasOpenPrivatePix,
} = pixSlice.actions;
