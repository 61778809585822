import { ButtonProps as MuiButtonProps, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { StyledButton } from './styles';

interface ButtonProps extends MuiButtonProps {
  label?: string | ReactElement;
  loading?: boolean;
  fontSize?:
    | string
    | {
        xs: string;
        sm: string;
        md: string;
      };
  onClick: (values: any) => void;
}

export const Button = (props: ButtonProps) => {
  const { label = '', loading = false, fontSize, ...rest } = props;
  return (
    <StyledButton {...rest} loading={loading}>
      <Typography
        sx={{
          fontSize: fontSize || {
            xs: '12px',
            sm: '14px',
            md: '16px',
          },
        }}
      >
        {label}
      </Typography>
    </StyledButton>
  );
};
