import { find, includes } from 'lodash';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Modal } from '../..';
import {
  StyledButtonsWrapper,
  StyledStepWrapper,
} from '../../../pages/TicketPurchase/styles';
import {
  useDispatch,
  useSelector,
  fetchAuthenticatePrivatePayment,
  fetchCancelPrivatePix,
  pixSlice,
  vehiclesSlice,
  privateTicketPurchaseSlice,
} from '../../../stores';

export const ManagePrivatePixModal: React.FC = () => {
  const dispatch = useDispatch();
  const { driverId } = useSelector((state) => state.profileReducer);
  const { vehicles } = useSelector((state) => state.vehiclesReducer);
  const {
    managePrivatePixIsOpen,
    managePrivatePixIsLoading,
    openPixPrivatePaymentId,
  } = useSelector((state) => state.pixReducer);

  const handleVerifyPix = async () => {
    console.log('verificando');
    console.log(openPixPrivatePaymentId);
    if (openPixPrivatePaymentId) {
      dispatch(
        fetchAuthenticatePrivatePayment({
          driverId: driverId.toString(),
          privateParkingPaymentId: openPixPrivatePaymentId,
        }),
      ).then(async (res: any) => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(
            privateTicketPurchaseSlice.actions.setOpenPrivateTicketData(
              res.payload.data,
            ),
          );
          dispatch(pixSlice.actions.toggleManagePrivatePixIsOpen());
          toast.success('Pix pago com sucesso');
        }
      });
    }
  };

  const handleCancelPix = async () => {
    if (openPixPrivatePaymentId) {
      await dispatch(
        fetchCancelPrivatePix({
          driverId: driverId.toString(),
          privateParkingPaymentId: openPixPrivatePaymentId,
        }),
      ).then((res: { meta: { requestStatus: string } }) => {
        if (res.meta.requestStatus === 'fulfilled') {
          toast.success('Pix cancelado com sucesso.');
          dispatch(pixSlice.actions.toggleManagePrivatePixIsOpen());
        }
      });
    }
  };

  const handleClose = () => {
    dispatch(pixSlice.actions.toggleManagePrivatePixIsOpen());
  };

  return (
    <Modal
      open={managePrivatePixIsOpen}
      onClose={handleClose}
      title='Gerenciar Pix em aberto'
    >
      <StyledStepWrapper>
        <StyledButtonsWrapper>
          <Button
            variant='contained'
            label='Verificar pagamento'
            onClick={handleVerifyPix}
            color='primary'
            loading={managePrivatePixIsLoading}
            style={{ width: '100%' }}
          />
        </StyledButtonsWrapper>
        <StyledButtonsWrapper>
          <Button
            variant='contained'
            label='Cancelar Pix'
            onClick={handleCancelPix}
            color='secondary'
            loading={managePrivatePixIsLoading}
            style={{ width: '100%' }}
          />
        </StyledButtonsWrapper>
        <StyledButtonsWrapper>
          <Button
            label='Voltar'
            onClick={handleClose}
            color='secondary'
            loading={managePrivatePixIsLoading}
            style={{ width: '100%' }}
          />
        </StyledButtonsWrapper>
      </StyledStepWrapper>
    </Modal>
  );
};
