import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchGetPrivateHistoryListService,
  fetchGetPrivateParkingTicketService,
} from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from '../Store';
import { PrivateHistoryStateType } from '../types';
import { toast } from 'react-toastify';

const initialState: PrivateHistoryStateType = {
  privateHistoryLoading: false,
  privateHistory: [],
  pageOffset: 1,
  pageSize: 3,
  privateTicketDetails: null,
  privateTicketDetailsIsLoading: false,
  privateTicketDetailsIsOpen: false,
};

export const fetchGetPrivateHistoryList = createAsyncThunk(
  'getPrivateHistoryList',
  async (
    params: { driverId: string; size?: number; reset?: boolean },
    { getState },
  ) => {
    const state = getState() as RootState;
    return await fetchGetPrivateHistoryListService({
      ...params,
      offset: params.reset ? 1 : state.privateHistoryReducer.pageOffset + 1,
      size: state.privateHistoryReducer.pageSize,
    });
  },
);

export const fetchGetPrivateTicketDetails = createAsyncThunk(
  'getPrivateParkingTicket',
  async (params: { driverId: string; ticketId: string }) => {
    return await fetchGetPrivateParkingTicketService(params);
  },
);

export const privateHistorySlice = createSlice({
  name: 'privateHistory',
  initialState,
  reducers: {
    togglePrivateHistoryLoading(state) {
      state.privateHistoryLoading = !state.privateHistoryLoading;
    },
    setPrivatePageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    resetPrivateOffset(state) {
      state.pageOffset = 1;
    },
    togglePrivateTicketDetailsIsOpen(state) {
      state.privateTicketDetailsIsOpen = !state.privateTicketDetailsIsOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetPrivateHistoryList.pending, (state) => {
      return {
        ...state,
        privateHistoryLoading: true,
      };
    }),
      builder.addCase(fetchGetPrivateHistoryList.fulfilled, (state, res) => {
        if (res.meta.arg.reset) {
          return {
            ...state,
            pageOffset: 1,
            privateHistory: res.payload.data,
            privateHistoryLoading: false,
          };
        } else {
          return {
            ...state,
            pageOffset: state.pageOffset + 1,
            privateHistory: [...state.privateHistory, ...res.payload.data],
            privateHistoryLoading: false,
          };
        }
      }),
      builder.addCase(fetchGetPrivateHistoryList.rejected, (state, res) => {
        return {
          ...state,
          historyLoading: false,
        };
      });

    builder.addCase(fetchGetPrivateTicketDetails.pending, (state) => {
      return {
        ...state,
        privateTicketDetailsIsLoading: true,
      };
    }),
      builder.addCase(fetchGetPrivateTicketDetails.fulfilled, (state, res) => {
        return {
          ...state,
          privateTicketDetails: res.payload.data,
          privateTicketDetailsIsLoading: false,
        };
      }),
      builder.addCase(fetchGetPrivateTicketDetails.rejected, (state, res) => {
        return {
          ...state,
          privateTicketDetailsIsLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  togglePrivateHistoryLoading,
  setPrivatePageSize,
  resetPrivateOffset,
  togglePrivateTicketDetailsIsOpen,
} = privateHistorySlice.actions;
