// All user related database operations can be defined here.
import { api } from '..';
import {
  GetPrivateHistoryListResponse,
  GetPrivateParkingTicketResponse,
} from './types';

export * from './types';

export const fetchGetPrivateHistoryListService = async (params: {
  driverId: string;
  offset: number;
  size: number;
}) => {
  const { driverId, offset, size } = params;
  return await api
    .get<GetPrivateHistoryListResponse>(
      `privateparkings/driver/${driverId}/payments?offset=${offset}&size=${size}`,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchGetPrivateParkingTicketService = async (params: {
  driverId: string;
  ticketId: string;
}) => {
  const { driverId, ticketId } = params;
  return await api
    .get<GetPrivateParkingTicketResponse>(
      `privateparkings/driver/${driverId}/${ticketId}`,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};
