import { AxiosError, AxiosRequestConfig } from 'axios';
import { includes, isString } from 'lodash';
import { toast } from 'react-toastify';
import { api } from '.';
import { authSlice, Store } from '../stores';
import {
  fetchLoginUrl,
  fetchCellphoneGetCodeUrl,
  fetchEmailGetCodeUrl,
  fetchCellphoneValidateCodeUrl,
  fetchEmailValidateCodeUrl,
  fetchSignupUrl,
  fetchLoginWithFBUrl,
  fetchPasswordRecoveryUrl,
} from './Auth/constants';

const OnRequestSuccess = async (config: AxiosRequestConfig) => {
  if (
    config.url !== fetchLoginUrl &&
    config.url !== fetchCellphoneGetCodeUrl &&
    config.url !== fetchEmailGetCodeUrl &&
    config.url !== fetchCellphoneValidateCodeUrl &&
    config.url !== fetchEmailValidateCodeUrl &&
    config.url !== fetchSignupUrl &&
    config.url !== fetchLoginWithFBUrl &&
    config.url !== fetchPasswordRecoveryUrl &&
    !includes(config.url, 'drivers/terms')
  ) {
    const accessToken = localStorage.getItem('NITROT:token');

    if (!config.headers) config.headers = {};
    config.headers.Authorization = accessToken ? `${accessToken}` : '';
    config.url = `${config.baseURL}${config.url}`;
  }

  return config;
};

const OnResponseFail = async (error: AxiosError) => {
  if (error.response) {
    if (
      error.response.status === 401 &&
      !includes(error.response.config.url, 'compliance')
    ) {
      localStorage.removeItem('NITROT:token');
      localStorage.removeItem('NITROT:driverId');
      window.dispatchEvent(new Event('storage'));
      Store.dispatch(authSlice.actions.logout());
      window.location.reload();
    } else if (error.response.status === 404) {
      toast.error('Requisição não encontrada.');
    } else if (error.response.status === 400 || error.response.status === 403) {
      if (
        !includes(error.response.config.url, 'openticket') &&
        !includes(error.response.config.url, 'openpayment') &&
        !includes(error.response.config.url, 'cloudpark')
      ) {
        toast.error(error.response.data.data);
      }
      if (includes(error.response.config.url, 'cloudpark')) {
        if (isString(error.response.data.data)) {
          const parsedData = JSON.parse(error.response.data.data);
          toast.error(parsedData.message);
        } else {
          toast.error(error.response.data.data.message);
        }
      }
    } else {
      toast.error('Ocorreu um erro. Tente novamente mais tarde.');
    }
  }
  return Promise.reject(error);
  throw error;
};

export const setupInterceptors = () => {
  api.interceptors.request.use(OnRequestSuccess, undefined);
  api.interceptors.response.use(undefined, OnResponseFail);
};
