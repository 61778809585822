import { Typography, Card, CardContent, CardMedia, Grid } from '@mui/material';
import moment from 'moment';
import React from 'react';
import {
  fetchGetPrivateTicketDetails,
  privateHistorySlice,
  useDispatch,
  useSelector,
} from '../../../stores';
import { palette } from '../../../theme';
import { handleDateTime, handleMoney } from '../../../utils';

export const PrivateTicketBox = (props: {
  id: string;
  plate: string;
  price: string;
  parkingTicketId: string;
  registerDateTime: string;
  startDateTime: string;
  paymentType: string;
}) => {
  const dispatch = useDispatch();
  const { driverId } = useSelector((state) => state.profileReducer);

  const handleTicketDetails = () => {
    dispatch(
      fetchGetPrivateTicketDetails({
        driverId: driverId.toString(),
        ticketId: props.id,
      }),
    );
    dispatch(privateHistorySlice.actions.togglePrivateTicketDetailsIsOpen());
  };

  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
      <Card
        sx={{
          borderRadius: '10px',
          minWidth: {
            xs: '100%',
            sm: 205,
          },
          height: 180,
          boxShadow: 5,
          cursor: 'pointer',
        }}
        onClick={handleTicketDetails}
      >
        <Grid
          item
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
          }}
        >
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: '0.75em',
                      sm: '0.75em',
                    },
                  }}
                  noWrap
                >
                  <b>
                    {moment(props.registerDateTime).format(
                      'DD/MM/yyyy HH:mm:ss',
                    )}
                  </b>
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Typography
                  color={palette.success.main}
                  sx={{
                    fontSize: {
                      xs: '0.75em',
                      sm: '0.75em',
                    },
                  }}
                  noWrap
                >
                  {handleMoney(props.price)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: '0.75em',
                      sm: '0.75em',
                    },
                    width: '40px',
                    textOverflow: 'ellipsis',
                  }}
                  noWrap
                >
                  <b>Placa:</b> {props.plate}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: '0.75em',
                      sm: '0.75em',
                    },
                  }}
                  noWrap
                >
                  <b>Código:</b> {props.parkingTicketId}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: '0.75em',
                      sm: '0.75em',
                    },
                  }}
                  noWrap
                >
                  <b>Entrada:</b> {handleDateTime(props.startDateTime)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: '0.75em',
                      sm: '0.75em',
                    },
                  }}
                  noWrap
                >
                  <b>Método de pagamento:</b> {props.paymentType}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Card>
    </Grid>
  );
};
